export function createQueryString(data): string {
  const qs = Object.keys(data).filter((key) => data[key] !== undefined)
    .map((k) => {
      if (typeof data[k] === 'object') {
        let querySegment = '';
        data[k].forEach((item, index) => {
          querySegment +=
            `${encodeURIComponent(k)}[]=${encodeURIComponent(item)}`;
          if (index < data[k].length - 1) {
            querySegment += '&';
          }
        });
        return querySegment;
      }
      return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;

    })
    .join('&');

  return qs.length > 0 ? `?${qs}` : '';
}

export const createApiStringFromObject = (obj: Record<string, string | number | boolean>) => Object.entries(obj).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');

export const convertObjectToFormData = (obj) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value.toString());
  });

  return formData;
};
