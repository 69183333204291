import omit from 'lodash/omit';
import { ApiTokenService } from '@/services/instances/apiTokenService';
import { notificationService } from '@/services/instances/notificationService';
import type { IFetchDataParams, IFetchDataReturn } from '@/services/interfaces';
import { translate } from '@/utils/translate';
import type { Maybe } from '@/types/types';
import type { ApiClientConfig } from './ApiClient.types';

const MAX_COUNT_OF_REFETCHING_TOKEN = 1;

const apiTokenService = new ApiTokenService();

export class ApiClientBase {
  protected config: ApiClientConfig;
  private readonly defaultHeaders: RequestInit['headers'];
  private countOfRetchingToken = 0;

  constructor(config: ApiClientConfig) {
    this.config = config;
    this.defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  async getApiToken() {
    if (apiTokenService.get()) {
      return apiTokenService.get();
    }

    return await this.fetchApiToken();
  }

  protected async fetchData<T>(props: IFetchDataParams): IFetchDataReturn<T> {
    const {
      queryString,
      method = 'GET',
      body,
      apiVersion = 3,
      additionalHeaders = {},
      signal,
      oldTextApi,
    } = props;

    let identityParam = 'internal/';
    let apiVersionParam = 'api/v3';
    switch (apiVersion) {
      case 0:
        apiVersionParam = '';
        identityParam = '';
        break;
      case 1:
        apiVersionParam = 'api/';
        break;
      case 2:
        apiVersionParam = 'api/v2/';
        identityParam = '';
        break;
      default:
        apiVersionParam = 'api/v3/';
        break;
    }

    const token = await this.getApiToken();

    const headers: RequestInit['headers'] = {
      ...this.defaultHeaders,
      Authorization: `Bearer ${token}`,
      ...additionalHeaders,
    };

    let bodyToPass = null;

    switch (headers['Content-Type']) {
      case 'application/x-www-form-urlencoded':
        bodyToPass = {
          body: (new URLSearchParams(body as [])),
        };
        break;
      case 'application/json':
      default:
        bodyToPass = body ? { body: JSON.stringify(body) } : {};
        break;
    }

    if (body instanceof FormData || additionalHeaders['Accept'] === '*/*') {
      delete headers['Content-Type'];

      bodyToPass = {
        body,
      };
    }

    const response = await fetch(`${this.config.baseUrl}/${identityParam}${apiVersionParam}${queryString}`, {
      headers,
      credentials: 'include',
      method,
      signal,
      ...bodyToPass,
    });

    const { status } = response;

    if (oldTextApi) {
      return { data: await response.text() as T, ...omit(response, 'json') };
    }

    if (status === 401) {
      const res = (await response.json());
      if (res?.code === 'LOGOUT') {
        notificationService.error(translate('Api.notifications.deleted_account.title'));
        const { status: logoutStatus } = await this.fetchData({
          queryString: 'auth/logout/0',
          apiVersion: 0,
        });

        if (logoutStatus === 200) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);

          return;
        }
      }

      // Try to fetch token again if request is unauthorized or forbidden
      if (this.countOfRetchingToken < MAX_COUNT_OF_REFETCHING_TOKEN) {
        apiTokenService.remove();
        await this.fetchApiToken();
        this.countOfRetchingToken += 1;
        return;
      }

      throw new Error('Unauthorized');
    }

    if (status === 403) {
      throw new Error('Forbidden');
    }

    const contentType = response.headers.get('content-type');
    let data: T = response as T;

    if (status === 204) {
      data = '' as T;
      // TODO: fix: we should change text/html with application/json on backend
    } else if (contentType === 'application/json' || (contentType.includes('text/html') && !props.readTextHtml)) {
      try {
        data = await response.json() as T;
      } catch (e) {
        data = { success: true } as T;
      }
    }

    return { data, ...omit(response, 'json') };
  }

  protected async fetchApiToken(): Promise<Maybe<string>> {
    let apiToken = apiTokenService.get();

    if (!apiToken) {
      const response = await fetch(
        `${this.config.baseUrl}/auth/token`,
        {
          headers: this.defaultHeaders,
        },
      );

      if (response.status === 200) {
        apiToken = await response.text();
        apiTokenService.set(apiToken, { expires: 1, secure: true });
      }
    }

    return apiToken;
  }
}
