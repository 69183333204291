export class TrelloApiKeyStorage {
  private key = 'apiKey';

  async get() {
    return await t.get('member', 'private', this.key, '');
  }

  async remove() {
    return await t.remove('member', 'private', this.key);
  }

  async set(apiKey: string) {
    return await t.set('member', 'private', this.key, apiKey);
  }
}
