import {
  ApiErrorTextHandler,
} from '@/services/RestApiClientFactory/ApiErrorHandler/ApiErrorTextHandler/ApiErrorTextHandler';
import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import { createApiStringFromObject } from '@/services/RestApiClientFactory/utils';
import type { ApiClientTimerGetParams, ApiClientTimerPostParams, ApiClientTimerResponse } from './ApiClientTimer.types';

export class ApiClientTimer extends ApiClientBase {
  async fetchApiToken() {
    return this.config.apiToken || super.fetchApiToken();
  }

  async get(params: ApiClientTimerGetParams = {
    action: 'status',
    service: 'tc_web',
  }) {
    const { action, service } = params;
    const response = await this.fetchData<ApiClientTimerResponse>({
      queryString: 'timer',
      apiVersion: 1,
      method: 'POST',
      body: {
        action,
        service,
      },
    });

    if (!response.ok) {
      throw new ApiErrorTextHandler('', {
        text: await response.text(),
        ok: response.ok,
      });
    }

    return response;
  }

  async post(params: ApiClientTimerPostParams) {
    const response = await this.fetchData<any>({
      queryString: 'third_party/api/timer',
      apiVersion: 0,
      method: 'POST',
      body: createApiStringFromObject(params),
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (!response.ok) {
      throw new ApiErrorTextHandler('', {
        text: await response.text(),
        ok: response.ok,
      });
    }

    return response;
  }
}
